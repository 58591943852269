import React, { useEffect, useMemo, useReducer, useCallback, useState } from 'react';
import { useParams } from "react-router-dom";
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import {
  Container, Typography, Dialog, DialogTitle, IconButton, Box, useMediaQuery, useTheme, Fab, DialogContent
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useAppBarContext } from '../components/AppBarContext';
import CardDetail from './CardDetail';
import debounce from 'lodash.debounce';
import { initialState, reducer } from './reducer';
import Filters from './Filters';
import CardGrid from './CardGrid';
import CardList from './CardList';
import FloatingUserBar from './FloatingUserBar';
import { currencySymbols } from './constants';
import Loading from '../components/Loading';
import withAnalytics from '../hoc/withAnalytics';
import { backgroundScroll } from '../utils/BackgroundScroll'; // Import the scroll control utility

const CardMarketplace = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedCountry, exchangeRates } = useAppBarContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showScrollTop, setShowScrollTop] = useState(false);
  const { setId, cardId, username } = useParams();
  const [userId, setUserId] = useState(null);

  // Handle background scroll lock when dialog opens/closes
  useEffect(() => {
    if (state.selectedCard) {
      backgroundScroll.disableScroll(true); // Disable scroll when dialog is open
    } else {
      backgroundScroll.disableScroll(false); // Re-enable scroll when dialog is closed
    }

    return () => {
      backgroundScroll.disableScroll(false); // Clean up on component unmount
    };
  }, [state.selectedCard]);

  useEffect(() => {
    const fetchUserId = async () => {
      if (username) {
        try {
          const userDocRef = query(collection(db, 'users'), where('username', '==', username));
          const userSnapshot = await getDocs(userDocRef);

          if (!userSnapshot.empty) {
            const userDoc = userSnapshot.docs[0];
            setUserId(userDoc.id);
          } else {
            console.warn('User not found');
          }
        } catch (error) {
          console.error('Error fetching user ID:', error);
        }
      }
    };
    fetchUserId();
  }, [username]);

  const fetchCardsForSet = useCallback(async (setId) => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
  
      // Fetch all cards for the specified set
      const cardsSnapshot = await getDocs(collection(db, "sets", setId, "cards"));
      const cardsData = await Promise.all(
        cardsSnapshot.docs.map(async (doc) => {
          const cardData = { docName: doc.id, setId, ...doc.data() };
  
          // Fetch listings for each card
          const listingsSnapshot = await getDocs(collection(db, "sets", setId, "cards", doc.id, "listings"));
          const listingsData = listingsSnapshot.docs.reduce((acc, listingDoc) => {
            // Only include listings if they have valid conditions and quantities
            const data = listingDoc.data();
            const validListings = Object.entries(data).filter(([condition, listing]) => 
              condition && listing && listing.quantity > 0 && listing.condition
            );
  
            if (validListings.length > 0) {
              acc[listingDoc.id] = validListings.map(([condition, listing]) => ({
                condition,
                ...listing,
              }));
            }
            return acc;
          }, {});
  
          // Count only listings with valid conditions and quantities
          const validListingsCount = Object.values(listingsData).reduce(
            (count, listingsArray) => count + listingsArray.length,
            0
          );
  
          cardData.listings = listingsData;
          cardData.listingsCount = validListingsCount;
          return cardData;
        })
      );
  
      dispatch({ type: "UPDATE_CARDS", payload: cardsData });
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      console.error('Error fetching cards for set:', error);
      dispatch({ type: "SET_LOADING", payload: false });
    }
  }, [userId, dispatch]);
  
  
  useEffect(() => {
    if (setId && cardId) {
      dispatch({
        type: 'SET_SELECTED_CARD',
        payload: { setId, docName: cardId },
      });
      // Fetch card data for the specific set if needed
      fetchCardsForSet(setId);
    }
  }, [setId, cardId, fetchCardsForSet]);
  
  const fetchSetsAndCards = useCallback(async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
  
      const setsSnapshot = await getDocs(collection(db, "sets"));
      const setsData = setsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      let selectedSet = setId || setsData.find(set => set.code === "1")?.id;
      if (!selectedSet && setsData.length > 0) {
        selectedSet = setsData[0].id;
      }
  
      dispatch({ type: "SET_DATA", payload: { sets: setsData, selectedSet } });
  
      if (selectedSet) {
        await fetchCardsForSet(selectedSet);
      }
    } catch (error) {
      console.error("Error fetching sets:", error);
    } finally {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  }, [setId, fetchCardsForSet]);
  
  
  useEffect(() => {
    // Fetch sets and cards if userId is available or username is not provided
    if (userId !== null || !username) {
      fetchSetsAndCards();
    }
  }, [userId, username, fetchSetsAndCards]);
  
  
  
  const handleSetChange = async (event, value) => {
    dispatch({ type: "SET_SELECTED_SET", payload: value });
    if (value) {
      await fetchCardsForSet(value);
    }
  };
  
  
  
  

  const handleRarityChange = useCallback((event, value) => {
    dispatch({ type: "SET_SELECTED_RARITIES", payload: value });
  }, []);

  const handleInkChange = useCallback((event, value) => {
    dispatch({ type: "SET_SELECTED_INKS", payload: value });
  }, []);

  const handleCardTypeChange = useCallback((event, value) => {
    dispatch({ type: "SET_SELECTED_CARD_TYPES", payload: value });
  }, []);

  const handleListingsChange = (event, value) => {
    dispatch({ type: "SET_SHOW_WITH_LISTINGS", payload: value });
  };

  const debouncedSearchChange = useMemo(() => debounce((value) => {
    dispatch({ type: "SET_SEARCH_TERM", payload: value });
  }, 100), [dispatch]);
  
  const handleSearchChange = (event) => {
    debouncedSearchChange(event.target.value);
  };
  

  const handleClearSearch = () => {
    dispatch({ type: "SET_SEARCH_TERM", payload: "" });
  };

  const handleSortChange = (event) => {
    dispatch({ type: "SET_SORT_BY", payload: event.target.value });
  };

  const handleViewToggle = () => {
    dispatch({ type: "SET_VIEW", payload: state.view === "grid" ? "list" : "grid" });
  };

  const handleCardClick = (card) => {
    dispatch({ type: "SET_SELECTED_CARD", payload: card });
  };

  const handleCloseDialog = () => {
    dispatch({ type: "SET_SELECTED_CARD", payload: null });
  };

  const filteredCards = useMemo(() => state.cards.filter(card =>
    (!state.selectedSet || state.selectedSet === card.setId) &&
    (!state.selectedRarities.length || state.selectedRarities.includes(card.rarity)) &&
    (!state.selectedInks.length ||
      (card.inks && card.inks.some(ink => state.selectedInks.includes(ink))) ||
      (card.ink && state.selectedInks.includes(card.ink))
    ) &&
    (!state.selectedCardTypes.length || state.selectedCardTypes.includes(card.type)) &&
    (!state.searchTerm || card.name?.toLowerCase().includes(state.searchTerm.toLowerCase()) ||
      card.version?.toLowerCase().includes(state.searchTerm.toLowerCase()) ||
      card.collector_number?.toLowerCase().includes(state.searchTerm.toLowerCase()) ||
      card.username?.toLowerCase().includes(state.searchTerm.toLowerCase())) &&  // Add this line for username search
    (!state.showWithListings || card.listingsCount > 0)
  ), [state.cards, state.selectedSet, state.selectedRarities, state.selectedInks, state.selectedCardTypes, state.searchTerm, state.showWithListings]);

  const sortedCards = useMemo(() => filteredCards.sort((a, b) => {
    if (state.sortBy === "az") return a.name.localeCompare(b.name);
    if (state.sortBy === "za") return b.name.localeCompare(a.name);
    if (state.sortBy === "price_high") return parseFloat(b.price || 0) - parseFloat(a.price || 0);
    if (state.sortBy === "price_low") return parseFloat(a.price || 0) - parseFloat(b.price || 0);
    if (state.sortBy === "card_low") return parseFloat(a.collector_number || 0) - parseFloat(b.collector_number || 0);
    if (state.sortBy === "card_high") return parseFloat(b.collector_number || 0) - parseFloat(a.collector_number || 0);
    return 0;
  }), [filteredCards, state.sortBy]);

  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return `${selectedCountry} ${currencySymbols[selectedCountry]}${(usdPrice * exchangeRates[selectedCountry]).toFixed(2)}`;
  };

  // Scroll to top button visibility logic
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Card Marketplace
      </Typography>
      <Filters
        state={state}
        handleSetChange={handleSetChange}
        handleRarityChange={handleRarityChange}
        handleInkChange={handleInkChange}
        handleCardTypeChange={handleCardTypeChange}
        handleSearchChange={handleSearchChange}
        handleClearSearch={handleClearSearch}
        handleSortChange={handleSortChange}
        handleViewToggle={handleViewToggle}
        handleListingsChange={handleListingsChange}
        searchTerm={state.searchTerm}
      />
      {username && (
        <FloatingUserBar username={username} />
      )}

      {state.loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Loading />
        </Box>
      ) : sortedCards.length === 0 ? ( // Check if there are no cards after filtering
        <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
          No items to show. Try adjusting the filters.
        </Typography>
      ) : state.view === "grid" ? (
        <CardGrid
          cards={sortedCards}
          handleCardClick={handleCardClick}
          convertPrice={convertPrice}
        />
      ) : (
        <CardList
          cards={sortedCards}
          handleCardClick={handleCardClick}
          convertPrice={convertPrice}
          userId={userId}
          loading={state.loading}
        />
      )}
      <Dialog
        open={Boolean(state.selectedCard)}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle>
          <IconButton
            onClick={handleCloseDialog}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {state.selectedCard && (
            <CardDetail
              setId={state.selectedCard.setId}
              id={state.selectedCard.docName}
              onClose={handleCloseDialog}
              userId={userId}
            />
          )}
        </DialogContent>
      </Dialog>

      {showScrollTop && (
        <Fab
          variant="extended"
          color="grey"
          aria-label="scroll to top"
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          sx={{
            position: "fixed",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          }}
        >
          <ArrowUpwardIcon />
        </Fab>
      )}
    </Container>
  );
};

export default withAnalytics(CardMarketplace);
