import React, { useState } from 'react';
import {
  TextField,
  Box,
  Button,
  Typography,
  Autocomplete,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

// Updated predefined postage options with service type and pricing
const predefinedOptions = [
  // Regular (tracked) options
  {
    label: "Regular - Small Envelope",
    serviceType: "Regular",
    maxWeight: "Up to 125g",
    dimensions: "130mm x 240mm (Up to 5mm thickness)",
    chargePerItem: "$4.75",
  },
  {
    label: "Regular - Medium Envelope",
    serviceType: "Regular",
    maxWeight: "Up to 500g",
    dimensions: "162mm x 229mm (Up to 20mm thickness)",
    chargePerItem: "$6.65",
  },
  {
    label: "Regular - Large Envelope",
    serviceType: "Regular",
    maxWeight: "Up to 500g",
    dimensions: "229mm x 324mm (Up to 20mm thickness)",
    chargePerItem: "$8.35",
  },
  // Untracked (stamped, no tracking) options
  {
    label: "Untracked - Small Envelope",
    serviceType: "Untracked",
    maxWeight: "Up to 250g",
    dimensions: "130mm x 240mm (Small letter)",
    chargePerItem: "$1.50",
  },
  {
    label: "Untracked - Medium Envelope",
    serviceType: "Untracked",
    maxWeight: "Up to 500g",
    dimensions: "162mm x 229mm",
    chargePerItem: "$4.50",
  },
  {
    label: "Untracked - Large Envelope",
    serviceType: "Untracked",
    maxWeight: "Up to 500g",
    dimensions: "229mm x 324mm",
    chargePerItem: "$7.50",
  },
  // Express options
  {
    label: "Express - Small Envelope",
    serviceType: "Express",
    maxWeight: "Up to 500g",
    dimensions: "110mm x 220mm",
    chargePerItem: "$8.85",
  },
  {
    label: "Express - Medium Envelope",
    serviceType: "Express",
    maxWeight: "Up to 500g",
    dimensions: "162mm x 229mm",
    chargePerItem: "$9.15",
  },
  {
    label: "Express - Large Envelope",
    serviceType: "Express",
    maxWeight: "Up to 500g",
    dimensions: "250mm x 353mm",
    chargePerItem: "$10.85",
  },
];

const courierOptions = ['Australia Post', 'Other'];
const serviceTypeOptions = ['Regular', 'Untracked', 'Express'];

const PostageOptions = ({ profile, addPostageOption, removePostageOption }) => {
  const [selectedCourier, setSelectedCourier] = useState('');
  const [selectedServiceType, setSelectedServiceType] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [methodFields, setMethodFields] = useState({
    method: '',
    maxWeight: '',
    dimensions: '',
    chargePerItem: '',
  });

  const postageOptions = profile.sellerPreferences.postageOptions || [];

  const handleOpenDialog = () => {
    setSelectedCourier('');
    setSelectedServiceType('');
    setMethodFields({
      method: '',
      maxWeight: '',
      dimensions: '',
      chargePerItem: '',
    });
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCourierChange = (value) => {
    setSelectedCourier(value);
    setSelectedServiceType(''); // Reset service type when courier changes
    setMethodFields({
      method: '',
      maxWeight: '',
      dimensions: '',
      chargePerItem: '',
    });
  };

  const handleServiceTypeChange = (value) => {
    setSelectedServiceType(value);
    setMethodFields({
      method: '',
      maxWeight: '',
      dimensions: '',
      chargePerItem: '',
    });
  };

  const handleMethodChange = (event, newValue) => {
    if (selectedCourier === 'Australia Post' && selectedServiceType) {
      const filteredOptions = predefinedOptions.filter(
        (option) => option.serviceType === selectedServiceType
      );
      const selectedOption = filteredOptions.find(
        (option) => option.label === newValue
      );
      if (selectedOption) {
        setMethodFields({
          method: selectedOption.label,
          maxWeight: selectedOption.maxWeight,
          dimensions: selectedOption.dimensions,
          chargePerItem: selectedOption.chargePerItem,
        });
      }
    } else {
      setMethodFields((prevFields) => ({
        ...prevFields,
        method: newValue,
      }));
    }
  };

  const handleFieldChange = (field, value) => {
    setMethodFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleSaveOption = async () => {
    const newOption = {
      ...methodFields,
      courier: selectedCourier,
      serviceType: selectedServiceType,
    };
    addPostageOption(newOption);
    handleCloseDialog();
  };

  const handleRemoveOption = async (index) => {
    removePostageOption(index);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Postage Options
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Set your Postage Options you will offer to your buyers.
      </Typography>
      {postageOptions.map((option, index) => (
        <Box key={index} mb={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <TextField
                value={option.courier || ''}
                label="Courier"
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                value={option.serviceType || ''}
                label="Service Type"
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                value={option.method || ''}
                label="Method"
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                value={option.maxWeight || ''}
                label="Max. Weight"
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                value={option.chargePerItem || ''}
                label="Charge per Item"
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
          <Box textAlign="right" mt={1}>
            <Button
              onClick={() => handleRemoveOption(index)}
              variant="contained"
              color="secondary"
            >
              Remove
            </Button>
          </Box>
        </Box>
      ))}
      <Box
        mt={2}
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Button
          onClick={handleOpenDialog}
          variant="contained"
          color="primary"
          sx={{ mb: { xs: 1, sm: 0 } }}
        >
          Add Postage Option
        </Button>
      </Box>

      {/* Dialog for adding a new postage option */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Select Courier and Service Type</DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Courier"
            value={selectedCourier}
            onChange={(e) => handleCourierChange(e.target.value)}
            fullWidth
            variant="outlined"
            margin="normal"
          >
            {courierOptions.map((courier) => (
              <MenuItem key={courier} value={courier}>
                {courier}
              </MenuItem>
            ))}
          </TextField>

          {selectedCourier === 'Australia Post' && (
            <TextField
              select
              label="Service Type"
              value={selectedServiceType}
              onChange={(e) => handleServiceTypeChange(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            >
              {serviceTypeOptions.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          )}

          {selectedCourier === 'Australia Post' && selectedServiceType && (
            <>
              <Autocomplete
                freeSolo
                options={predefinedOptions
                  .filter((option) => option.serviceType === selectedServiceType)
                  .map((option) => option.label)}
                onChange={handleMethodChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Method"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
              />
              <TextField
                label="Max. Weight"
                value={methodFields.maxWeight}
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) => handleFieldChange('maxWeight', e.target.value)}
              />
              <TextField
                label="Dimensions"
                value={methodFields.dimensions}
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) =>
                  handleFieldChange('dimensions', e.target.value)
                }
              />
              <TextField
                label="Charge per Item"
                value={methodFields.chargePerItem}
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) =>
                  handleFieldChange('chargePerItem', e.target.value)
                }
              />
            </>
          )}

          {selectedCourier === 'Other' && (
            <>
              <TextField
                label="Method (e.g.: Envelope, Parcel, etc.)"
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) =>
                  handleFieldChange('method', e.target.value)
                }
              />
              <TextField
                label="Max. Weight"
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) =>
                  handleFieldChange('maxWeight', e.target.value)
                }
              />
              <TextField
                label="Dimensions"
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) =>
                  handleFieldChange('dimensions', e.target.value)
                }
              />
              <TextField
                label="Charge per Item"
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={(e) =>
                  handleFieldChange('chargePerItem', e.target.value)
                }
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveOption} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PostageOptions;
